<template>
  <div class="pb-2">
    <portal to="body-top">
      <customer-name title=" " />
    </portal>
    <l-table
      ref="lTableRef"
      :module-name="MODULE_NAME"
      :table-columns="tableColumns"
      :create-page-url="{ name: 'home-customers-contacts-create' }"
      trash-list-path="home-customers-contacts-trash"
      :can-create="ACCESS_ABILITY_FOR_CONTACTS"
      :create-btn-dashed="true"
      :can-show-trash-list="ACCESS_ABILITY_FOR_CONTACTS"
      :table-config-options="tableConfigOptions()"
      :is-pagination-available="false"
      create-btn-icon="LAddButtonGreenIcon"
    >
      <template #cell(actions)="{ data }">
        <div class="text-nowrap">
          <Can
            :i="ACCESS_ABILITY_FOR_CONTACTS.action"
            :a="ACCESS_ABILITY_FOR_CONTACTS.subject"
          >

            <feather-icon
              icon="LTrashIconKits"
              size="32"
              class="cursor-pointer mr-1"
              @click="setInActive(data.item)"
            />
            <feather-icon
              icon="LStockEyeIcon"
              class="cursor-pointer"
              size="32"
              @click="update(data.item, $event)"
            />
          </Can>
        </div>
      </template>
      <template #cell(type)="{ data }">
        {{ getTypeText(data.item) }}
      </template>
      <template #cell(first_name)="{ data }">
        {{ data.item.first_name + ' ' + data.item.last_name }}
      </template>
      <template #cell(cell_no)="{ data }">
        {{ data.item ? getPhoneNumber(data.item) : '—' }}
      </template>
      <template #cell(default)="{ data }">
        <div
          :id="data"
          class="d-flex align-items-center justify-content-center w-50"
          style="width: max-content; height: 20px;  "
          :style="{pointerEvents: isLoadingOfSetDisabled ? 'none':'all'}"
          @click="setDefault(data)"
        >
          <div class="cursor-pointer">
            <b-form-radio
              :id="data.item.id.toString()"
              :checked="data.item.default"
              style="pointer-events: none"
              :value="true"
              :disabled="isLoadingOfSetDisabled"
            />
            <b-spinner
              v-if="isSettling(data.item.id)"
              style="margin-bottom: 4px;"
              class="ml-1"
              small
              label="Small Spinner"
              type="grow"
              variant="success"
            />
          </div>
        </div>
      </template>
      <template #pagination>
        {{ '' }}
      </template>
    </l-table>
    <div
      v-if="accountHolderNotification || billingContactNotification || shippingContactNotification || canOrderPlaceNotification"
      class="notification-section"
    >
      <div
        v-if="accountHolderNotification"
        class="notification alert"
      >
        <p class="font-weight-bolder">
          {{ $t('Account Holder is Missing!') }}
        </p>
        <p>
          {{ $t('Account Holder is required for all Accounts before they can place any Purchase or Rental Orders or get verified by ID Scan.') }}
        </p>
      </div>
      <div
        v-if="billingContactNotification"
        class="notification alert"
      >
        <p class="font-weight-bolder">
          {{ $t('Billing Contact is Missing!') }}
        </p>
        <p>
          {{ $t('Billing Contact is required for all Accounts before they can place any Purchase or Rental Orders.') }}
        </p>
      </div>
      <div
        v-if="shippingContactNotification"
        class="notification alert"
      >
        <p class="font-weight-bolder">
          {{ $t('Shipping Contact is Missing!') }}
        </p>
        <p>
          {{ $t('Shipping Contact is required for all Accounts before they can place any Purchase or Rental Orders.') }}
        </p>
      </div>
      <div
        v-if="canOrderPlaceNotification"
        class="notification alert"
      >
        <p class="font-weight-bolder">
          {{ $t('Can Place Order is Missing!') }}
        </p>
        <p>
          {{ $t('Can Place Order is required for all Accounts before they can place any Purchase or Rental Orders.') }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>

// components
import { BSpinner, BFormRadio } from 'bootstrap-vue'
import LTable from '@/views/components/LTable/LTable.vue'
import { isObject } from '@vueuse/core'
import config, { convertTypesToTitleByTypeValue } from '../contactsConfig'
import CustomerName from '../../../components/CustomerName.vue'

export default {
  name: 'ContactsList',
  components: {
    LTable,
    CustomerName,
    BFormRadio,
    BSpinner,
  },
  data() {
    return {
      is_billing: '',
      is_shipping: '',
      is_account_holder: '',
      can_place_orders: '',
      isLoadingOfSetDisabled: false,
      idOfSettledDefault: null,
    }
  },
  computed: {
    contacts() {
      return this.$store.state[this.MODULE_NAME].contacts
    },
    accountHolderNotification() {
      return this.is_account_holder === 0
    },
    billingContactNotification() {
      return this.is_billing === 0
    },
    shippingContactNotification() {
      return this.is_shipping === 0
    },
    canOrderPlaceNotification() {
      return this.can_place_orders === 0
    },
  },
  watch: {
    contacts(newContacts) {
      this.updateContactCounts(newContacts)
    },
  },
  methods: {
    tableConfigOptions() {
      return {
        endpoint: `${this.MODULE_NAME}/getActiveContactsList`,
      }
    },
    refetchData() {
      this.$refs.lTableRef.refetchData()
    },
    setInActive(data) {
      this.confirmNotification(this, data, `${this.MODULE_NAME}/setInActive`,
        { text: 'It is possible to revert this', confirmButtonText: 'Yes, deactivate it' })
        .then(() => {
          this.refetchData()
        })
    },
    isSettling(id) {
      return this.isLoadingOfSetDisabled && this.idOfSettledDefault === id
    },
    setDefault(data) {
      const {
        item: { id },
      } = data
      const customerId = this.$route.params.id
      this.isLoadingOfSetDisabled = true
      this.idOfSettledDefault = id
      this.sendNotification(
        this,
        {
          customerId,
          id,
        },
        `${this.MODULE_NAME}/setDefault`,
      )
        .then(() => {
          this.refetchData()
          this.isLoadingOfSetDisabled = false
        })
    },
    update(data, e) {
      if (e.ctrlKey) {
        const route = this.$router.resolve({
          name: 'home-customers-contacts-update',
          params: { contact_id: data.id },
        })
        window.open(route.href)
      } else {
        this.$router.push({
          name: 'home-customers-contacts-update',
          params: { contact_id: data.id },
        })
      }
    },
    getTypeText(data) {
      // Making new array for Contact types to get their Title, by type value
      return convertTypesToTitleByTypeValue(data)
    },
    updateContactCounts(data) {
      this.is_billing = 0
      this.is_shipping = 0
      this.is_account_holder = 0
      this.can_place_orders = 0
      if (!data) {
        console.log('Data is undefined or empty') // Debug
        return
      }
      for (const item of data) {
        if (item?.is_billing) {
          this.is_billing++
        }
        if (item?.is_shipping) {
          this.is_shipping++
        }
        if (item?.is_account_holder) {
          this.is_account_holder++
        }
        if (item?.can_place_orders) {
          this.can_place_orders++
        }
      }
    },
    makePhoneNumber(obj) {
      return `+${obj?.countryCallingCode}${obj?.formatNational}`
    },
    getPhoneNumber(obj) {
      const { phone_one_object, phone_one } = obj
      if (phone_one_object && !isObject(phone_one_object)) {
        const phone = JSON.parse(phone_one_object)
        return phone ? `${this.makePhoneNumber(phone)} ${phone.phone_ext_val ? `Ext.${phone.phone_ext_val}` : ''}` : '—'
      } if (isObject(phone_one_object)) {
        return phone_one_object ? `${this.makePhoneNumber(phone_one_object)} ${phone_one_object.phone_ext_val ? `Ext.${phone_one_object.phone_ext_val}` : ''}` : '—'
      } if (phone_one) {
        return phone_one
      }
      return '—'

      // if (phone_one.length > 10) {
      //   return `(${phone_one.substring(0, 3)})${phone_one.substring(3, 6)}-${phone_one.substring(6, 10)} Ext ${phone_one.substring(10)}`
      // }
      // return `(${phone_one.substring(0, 3)})${phone_one.substring(3, 6)}-${phone_one.substring(6, 10)}`

      // if (cell_no) {
      //   // eslint-disable-next-line camelcase
      //   if (cell_no_object) {
      //     // This function can be deleted if formatNational is not used anymore
      //     const phone = JSON.parse(cell_no_object)
      //     return phone ? `${ph} ${phone.cell_no_ext_val ? `Ext.${phone.cell_no_ext_val}` : ''}` : '—'
      //   }
      //   return ph
      // }
      // eslint-disable-next-line camelcase
    },
  },
  setup() {
    const MODULE_NAME = 'contacts'
    const { tableColumns, ACCESS_ABILITY_FOR_CONTACTS } = config()
    return {
      tableColumns,
      MODULE_NAME,
      ACCESS_ABILITY_FOR_CONTACTS,
    }
  },
}
</script>

<style scoped lang="scss">
.dashed-btn-custom-style {
  width: 33px;
  height: 33px;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;

& .lightIcon {
    margin: 0;
  }
}
.notification-section {
  margin-bottom: 20px;
  width: 50%;
}
.notification {
  margin: 10px 0;
  padding: 10px;
  border-radius: 4px;
  background-color: #FFF3CD;
  border: 1px solid #dde3e7;
}
</style>
